<template>
  <div class="app-drawer--wrapper">
    <div class="app-drawer" :class="rootClass">
      <div class="app-drawer__header">
        <div class="app-drawer__header-title">
          {{ title }}
        </div>

        <button
          class="app-drawer__header-close"
          @click="() => handleClose()"
        >
          <b-icon
            class="app-drawer__header-close-icon"
            icon="x-circle-fill"
          />
        </button>
      </div>
      
      <div class="app-drawer__content">
        <slot />
      </div>
    </div>

    <div
      class="app-drawer--overlay"
      @click="() => handleClose(true)"
    />
  </div>
</template>

<script>
export default {
  name: "AppDrawer",

  props: {
    closeable: {
      type: Boolean,
      default: false,
    },
    direction: {
      type: String,
      default: "up",
    },
    title: {
      type: String,
      default: "",
    },
  },

  computed: {
    rootClass () {
      return [
        this.animateClass,
        this.directionClass,
      ]
    },
    animateClass () {
      return this.animate
        ? "app-drawer--show"
        : ""
    },
    directionClass () {
      const dir = ["up", "down", "left", "right"]

      if (dir.includes(this.direction)) {
        return `app-drawer--${this.direction}`
      }

      return "app-drawer--up"
    },
  },

  data () {
    return {
      animate: false,
    }
  },

  mounted () {
    document.querySelector("body").style.position = "fixed"
    
    setTimeout(() => {
      this.animate = true
    })
  },
  
  destroyed () {
    document.querySelector("body").style.position = "initial"
  },

  methods: {
    handleClick () {
      this.handleClose()

      this.$emit("click")
    },
    handleClose (overlay) {
      if (!overlay || overlay && this.closeable) {
        this.animate = false
  
        setTimeout(() => {
          this.$emit("hide")
        }, 300)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.app-drawer {
  $this: &;

  background-color: white;
  border: 1.2px solid #D1D1D1;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 4rem);
  padding: 1.5rem .5rem;
  position: fixed;
  row-gap: 1.5rem;
  transition: all .25s ease-in-out;
  width: 100%;
  z-index: 1000;

  &--up {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
    bottom: -100%;
    left: 0;
  }

  &--overlay {
    background-color: rgba(0, 0, 0, .4);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity .25s ease-in-out;
    width: 100%;
  }

  &#{$this} {
    &--show {
      &#{$this}--up {
        bottom: 0;
      }

      &+#{$this}--overlay {
        opacity: 1;
      }
    }
  }

  #{$this} {
    &__header {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      padding: 0 1rem;

      &-title {
        font-size: 2.25rem;
        font-weight: 700;
        line-height: 3rem;
      }

      &-close {
        border: none;
        border-radius: 100%;
        height: 2.75rem;
        width: 2.75rem;

        &-icon {
          color: #D9D9D9;
          height: 100%;
          width: 100%;
        }
      }
    }

    &__content {
      max-height: 100%;
      overflow: auto;
      padding: 0 1rem;
    }
  }
}
</style>
