<template src="./Activation.html" />

<script>
import { useStore } from "@/store/store.js"
import { computed } from "vue"

import ActivationList from "./components/ActivationList.vue"

import AppDrawer from "@/components/AppDrawer.vue"

export default {
  name: "Activation",

  components: {
    ActivationList,
    AppDrawer,
  },

  data() {
    return {
      showAsignComponent: false,
      value: 0,
      plans: {
        individual: {},
        familiar: {},
        selected: null,
      },
      services: [],
    };
  },

  computed: {
    component () {
      return this.device === "mobile" ? "app-drawer" : "b-modal"
    },
    infoPlans () {
      const plans = {}

      Object.keys(this.plans).map((plan) => {
        if (plan !== "selected" && this.plans[plan].planValue){
          plans[plan] = this.plans[plan]
        }
      })

      return plans
    },
    planName () {
      const nameIndividual = this.plans.individual.planName
      const nameFamiliar = this.plans.familiar.planName

      return nameIndividual || nameFamiliar
        ? nameIndividual || nameFamiliar
        : null
    },
    signOptions () {
      const options = []

      if (this.plans.individual.name) {
        options.push(this.getOption("individual"))
      }

      if (this.plans.familiar.name) {
        options.push(this.getOption("familiar"))
      }

      return options
    },
  },
  
  setup () {
    const store = useStore()

    return {
      env: computed(() => store.getters.enviroment),
      device: computed(() => store.getters.device),
      getPlanByCode: (code) => store.dispatch("getPlanByCode", code),
      getPlanById: (code) => store.dispatch("getPlanById", code),
    }
  },

  async created () {
    this.getPlans(this.$route.meta.plans[this.env])
  },

  async mounted () {
    window.addEventListener("resize", this.setDrawerOff)
    this.setDrawerOff()
  },

  methods: {
    async getPlans(codes) {
      const types = ["individual", "familiar"]

      await codes.map(async (code, index) => {
        const plan = await this.getPlanById(code)
        const split = plan.name.split(" - ") 
        const type = split.length > 2 ? split[2].toLowerCase() : types[index]
        
        plan.planServices.filter((service) => service.active).forEach((apiService) => {
          if (!this.services.find((service) => service.id === apiService.id)) {
            this.services.push({ id: apiService.id, name: apiService.name })
          }
        })

        this.plans[type] = {
          ...plan,
          planName: split[1],
          type,
        }
      })
    },
    getOption (type) {
      const plan = this.plans[type]
      const complement = type === "individual" ? "mim" : "família/amigos"

      return {
        id: plan.id,
        label: `Assinar para ${complement}`,
        text: `Assinar para <b>${complement}</b>`,
        value: plan.planValue,
        type,
      }
    },
    setSelectedPlan (option) {
      this.plans.selected = this.plans[option.type]
    }, 
    async toogleAsignComponent () {
      this.showAsignComponent = true

      if (this.device !== "mobile") {
        await this.showModal("asign-options")
      }
    },

    setDrawerOff () {
      this.showAsignComponent = false
      this.plans.selected = null
    },

    goToPayment() {
      this.$session.set("activation", this.plans.selected)

      this.$router.push({ path: `${this.$route.path}/payment` })
    },
    
    async showModal(modalId) {
      await this.$nextTick(() => {
        this.$bvModal.show(modalId)
      });
    },
  },
};
</script>

<style lang="scss" src="./Activation.scss" />
