<template>
  <div class="activation-list">
    <div class="activation-list__header">
      <div class="activation-list__header-text">
        Qual plano deseja assinar?
      </div>
    </div>

    <div class="activation-list__content">
      <b-form-group class="activation-list__content-list">
        <b-form-radio
          v-for="option in options"
          class="activation-list__content-list-item"
          name="plan-option"
          size="lg"
          :class="{
            'activation-list__content-list-item--selected': selected && selected.id === option.id
          }"
          :key="option.id"
          :value="option"
          v-model="selected"
          @input="handleClickSelect"
        >
          <div>
            <div class="activation-list__content-list-item-description">
              <span v-html="option.text" />

              <b>{{ option.value | monetary }}</b>
            </div>
            
            <div
              v-if="option.type === 'familiar'"
              class="activation-list__content-list-item-callout"
            >
              Para você e até 3 dependentes
            </div>
          </div>
        </b-form-radio>
      </b-form-group>
    </div>

    <div class="activation-list__footer">
      <button
        class="activation-list__footer-button"
        :disabled="!selected"
        @click="handleClickConfirm"
      >
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivationList",

  props: {
    options: {
      type: Array,
      default: () => ([]),
    },
  },

  data () {
    return {
      selected: null,
    }
  },

  computed: {
    buttonLabel () {
      return this.selected ? this.selected.label : "Assinar"
    },
  },

  methods: {
    handleClickSelect (data) {
      this.selected = data
      this.$emit("select", data)
    },
    handleClickConfirm () {
      this.$emit("confirm")
    },
  },
}
</script>

<style lang="scss" scoped>
.activation-list {
  margin-top: -.25rem;

  &__header {
    &-text {
      color: #AAA;
      font-size: 1.15rem;
    }
  }

  &__content {
    margin-top: 1rem;

    &-list {
      &-item {
        border-radius: 0.5rem;
        color: #888888;
        font-weight: 400;
        margin: 0 -0.5rem; // 0.5rem -0.5rem;
        padding: 0.5rem 0.5rem 0.5rem 2.375rem;

        &-description {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        &-callout {
          font-size: 1rem;
        }

        &--selected {
          background-color: #E5E5E5;
        }
      }

      :deep(.custom-control) {
        .custom-control-label {
          width: 100%;
        }
  
        .custom-control-input { 
          &:focus~.custom-control-label {
            &::before {
              box-shadow: none;
            }
          }

          &:checked~.custom-control-label {
            &::before {
              color: #fff;
              border-color: #FF8F00;
              background-color: #FF8F00;
            }
          }
        }
  
      }

    }
  }

  &__footer {
    align-items: center;
    display: flex;
    font-size: 1.4rem;
    font-weight: 700;
    gap: 1rem;

    &-button {
      background: #FF8F00;
      border-radius: 1.25rem;
      border: 0px;
      color: #FFFFFF;
      flex-basis: 0;
      flex-grow: 1;
      font-family: 'DM Sans';
      height: 4rem;

      &[disabled] {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
}
</style>
